<template>
  <div>
    <b-list-group flush>
      <b-list-group-item v-for="(item, index) in content" :key="'uri-' + index">
        {{ lookupURI(item.id) }}
        <b-row v-if="uris[item.id] != undefined">
          <b-col>
            {{ uris[item.id] }}
          </b-col>
          <b-col sm="auto">
            <b-button v-t="'pages.uris.delete'" @click="deleteURI(index)" />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-row>
      <b-col>
        {{ $t("components.urisComponent.usedToggle") }}
        <b-form-checkbox v-model="usedToggle" switch class="d-inline">
          {{
            usedToggle
              ? $t("components.urisComponent.usedUri")
              : $t("components.urisComponent.unusedUri")
          }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <base-auto-complete
      :graph-query="usedToggle ? completions : findUnusedURIs"
      :placeholder="$t('pages.uris.select')"
      @select="addURI"
    >
      <!-- What to show when we have no results -->
      <template v-slot:noResult>
        <span>{{ $t("components.baseList.noResult") }}</span>
      </template>
      <!-- Template for each element in the auto complete -->
      <template v-slot:option="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
      <!-- Template for a selected element in the auto complete -->
      <template v-slot:singleLabel="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
    </base-auto-complete>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "UrisComponent",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      uris: {},
      usedToggle: false,
      completions: gql`
        query findByURI($search: String!) {
          completions: findByURI(uri: $search) {
            name: uri
            id
          }
        }
      `,
      findUnusedURIs: gql`
        query findUnusedURIs {
          completions: findUnusedURIs {
            name: uri
            id
          }
        }
      `
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    deleteURI(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    addURI(element) {
      this.content.push({ id: element.id });
      this.$set(this.uris, element.id, element.uri);
      this.$emit("change");
    },
    lookupURI(id) {
      if (this.uris[id] != undefined) return;
      this.$apollo
        .query({
          query: gql`
            query uri($id: ID) {
              uri(id: $id) {
                id
                uri
              }
            }
          `,
          variables: { id: id }
        })
        .then(result => {
          this.$set(this.uris, result.data.uri.id, result.data.uri.uri);
        });
    }
  }
};
</script>
